@use '../0-settings/uefa-variables' as v;

// if chaged purge on Akamai this URLs
// /Content/fonts/icomoon/icomoon.woff

@font-face {
  font-family: 'icomoon';
  font-display: swap;
  src: url('#{v.$fonts-base-url}/icomoon/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Champions ExtraBold';
  font-display: swap;
  src:
    url('#{v.$fonts-base-url}/champions/champions-extrabold.woff2') format('woff2'),
    url('#{v.$fonts-base-url}/champions/champions-extrabold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
